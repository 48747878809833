import axios from '@/api/config/interceptor'
import { host } from '@/api/config/host'
import { _paramsToQueryString } from '@/utils/utils'

// 自动组合发票
export const AutoCombinedinvoice = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v3/Makeout/AutoCombinedinvoice`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 取消组合发票
export const CancelCombined = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v3/Makeout/CancelCombined`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 保存进度
export const StagingCombined = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v3/Makeout/StagingCombined`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 发票识别
export const DiscernInvoice = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v3/Makeout/DiscernInvoice`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 获取发票包列表
export const GetInvoicePackages = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v3/Makeout/GetInvoicePackages`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 获取发票包业务单明细
export const GetPackagesBBSDetail = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v3/Makeout/GetPackagesBBSDetail`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 上传发票
export const UploadInvoice = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v3/Makeout/UploadInvoice`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 查询发票集合
export const GetUploadInvoiceList = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v3/Makeout/GetUploadInvoiceList`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 发送至货主
export const SendInvoiceToOwner = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v3/Makeout/SendInvoiceToOwner`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 驳回发票
export const RejectedInvoice = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v3/Makeout/RejectedInvoice`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//平台驳回发票
export const RejectedInvoiceByPlat = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v3/Makeout/RejectedInvoiceByPlat`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//平台确认发票
export const CheckedInvoiceByPlat = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v3/Makeout/CheckedInvoiceByPlat`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 完成开票
export const CompleteInvoice = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v3/Makeout/CompleteInvoice`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 下载全部发票
export const DownloadInvoiceAll = (params,type) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v3/Makeout/DownloadInvoiceAll`, params,type).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 下载税务表格
export const DownloadTaxExcel = (params,type) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v3/Makeout/DownloadTaxExcel`, params,type).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 下载运单表格
export const DownloadBillExcel = (params,type) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v3/Makeout/DownloadBillExcel`, params,type).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}