<template>
  <div class="invoicing-process">
    <div style="margin: 8px 0px; display: flex; align-items: center">
      <!-- <span style="margin-left: 16px;">发票状态：</span>
            <el-select @change="changeType" v-model="searchForm.type" placeholder="请选择发票状态" style="width: 160px;">
                <el-option v-for="item in invoiceStatusList" :key="item.Code" :label="item.Name" :value="item.Code">
                </el-option>
            </el-select> -->
      <!-- <div v-if="wayBillTotal.bAuto">
                    <span style="margin-left: 16px;">发票项目：</span>
                    <el-input placeholder="请输入内容" v-model="invoiceName" style="width:260px">
                        <img slot="suffix" src="@/assets/image/question.png"
                            style="width:14px;height:14px;margin-top: 12px;margin-right: 3px;"
                            @click="flag.questionPreviewDialog = true" />
                    </el-input>
                    <el-button type="primary" @click="updateInvoiceName" style="margin-left: 10px;">保存
                    </el-button>
                    <span style="margin-left: 16px;">UKEY信息：</span>
                    <el-select v-model="keyId" placeholder="请选择" @change="getInvoiceStore(keyId)" style="width: 530px;">
                        <el-option v-for="item in ukeyOptions" :key="item.value" :label="item.label" :value="item.value"
                            value-key="KeyId">
                        </el-option>
                    </el-select>
                </div> -->
    </div>
    <!-- 表格 -->
    <el-table
      :data="currentInvoicePackagesList"
      :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
      key="invoicePackagesList"
      v-loading="loading"
    >
      <el-table-column
        fixed="left"
        align="center"
        type="index"
        label="序号"
        width="50"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="BPID"
        label="发票任务单号"
        width="200"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="BillCount"
        label="运单条数"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column align="center" prop="BaseAmount" label="运费">
        <template slot-scope="scope">
          <div style="color: #ff8b17">
            <!-- {{ scope.row.BaseAmount | formatMoney }} -->
            /
          </div>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="TaxAmount_Show"
        label="服务费/税费"
        width="200"
      >
        <template slot-scope="scope">
          <div style="color: #ff8b17">
            {{ scope.row.TaxAmount_Show | formatMoney }}
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="TotalAmount" label="运单总金额" width="200">
        <template slot-scope="scope">
          <div style="color: #ff8b17">
            {{ scope.row.TotalAmount | formatMoney }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="FeeType"
        label="开票类型"
        show-overflow-tooltip
      >
        <template slot-scope="scope">{{
          scope.row.FeeType == 0 ? "运费" : "技术服务费"
        }}</template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="InvoiceNumber"
        label="发票号"
        show-overflow-tooltip
      >
      </el-table-column>
      <!-- <el-table-column align="center" prop="CreateTime" label="开票时间" width="200" show-overflow-tooltip>
            </el-table-column> -->
      <el-table-column fixed="right" align="center" label="操作" width="350">
        <template slot-scope="scope">
          <div
            v-if="!flag.isFinishInvoice"
            style="display: flex; align-items: center; justify-content: center"
          >
            <el-button
              type="text"
              @click="uploadInvoice(scope.row)"
              :loading="flag.uploadDialogLoading"
              style="margin: 0px 5px 0px 10px"
            >
              查看/上传发票
            </el-button>
            <!-- <el-button type="text" @click="" style="margin:0px 5px 0px 10px;">预览
                        </el-button> -->
            <img
              src="@/assets/image/success.png"
              v-if="scope.row.Status != 0 && scope.row.Status != 2"
            />
            <el-tooltip
              class="item"
              effect="dark"
              content="请上传发票"
              placement="top-end"
              v-else
            >
              <img src="@/assets/image/fail-icon.png" />
            </el-tooltip>
          </div>
          <div v-else>
            <el-button type="text" @click="invoiceDetail(scope.row)">查看详情 </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div style="display: flex; align-items: center; margin: 16px 0px">
      <!-- 分页 -->
      <el-pagination
        class="pagination"
        background
        @current-change="handleCurrentChange"
        :current-page.sync="pagination.page"
        :page-size="pagination.pagesize"
        layout="total, prev, pager, next, jumper"
        :total="pagination.total"
      >
      </el-pagination>
      <!-- <el-radio-group v-model="invoiceTypeChosed" style="margin:0 auto;" v-if="wayBillTotal.bAuto">
                    <el-radio :label="it" v-for="(it, idx) in invoiceStoreList" :key="idx">{{ it.Describe }}
                        剩余票量：<span style="color: #FF2323;">{{ it.RemainNum }}</span></el-radio>
                </el-radio-group> -->
    </div>
    <!-- 右下角按钮-->
    <div style="width: 100%; text-align: center">
      <div v-if="!flag.isFinishInvoice">
        <el-button type="info" plain size="medium" icon="el-icon-back" @click="goBack"
          >返回
        </el-button>
        <el-button
          type="primary"
          size="medium"
          plain
          icon="el-icon-connection"
          @click="recombine"
          :loading="flag.recombineLoading"
          >重新组合</el-button
        >
        <el-button
          type="primary"
          size="medium"
          plain
          icon="el-icon-suitcase"
          @click="stagingCombined"
          :loading="flag.printDataStoreIng"
          >暂存</el-button
        >
        <el-button
          type="primary"
          size="medium"
          icon="el-icon-check"
          @click="finishInvoice"
          >开票完成</el-button
        >
        <!-- <el-button type="primary" size="medium" plain icon="el-icon-odometer" @click="">一键开票</el-button>
            <el-button type="primary" size="medium" plain icon="el-icon-refresh" @click="">刷新</el-button> -->
      </div>
      <div v-else>
        <el-button
          type="info"
          plain
          size="medium"
          icon="el-icon-back"
          @click="flag.isFinishInvoice = false"
          >返回
        </el-button>
        <el-button type="primary" size="medium" icon="el-icon-position" @click="sendBoss"
          >发送至货主</el-button
        >
      </div>
    </div>
    <!-- 上传发票dialog -->
    <el-dialog
      width="1500px"
      height="600px"
      :visible.sync="flag.uploadInvoiceDialog"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      v-if="flag.uploadInvoiceDialog"
    >
      <el-tabs v-model="flag.activeTab">
        <el-tab-pane label="纸质发票" name="1">
          <div style="height: 60vh; overflow-y: scroll">
            <!-- 提示 -->
            <el-alert style="margin-bottom: 8px" type="warning" :closable="false">
              <span slot="title">
                <span
                  >温馨提示：1.上传发票，将自动OCR识别发票信息；2.上传异常、需手动填写发票3项信息，平台将人工审核，并在全国增值税发票查验平台校验真伪。</span
                ><br />
              </span>
            </el-alert>
            <input
              type="file"
              style="display: none"
              @change="handleInvoiceUpload"
              multiple
              ref="invoice"
            />
            <input
              type="file"
              style="display: none"
              @change="handleSpecialInvoiceUpload"
              multiple
              ref="specialInvoice"
            />
            <input
              type="file"
              style="display: none"
              @change="handleVoucherUpload"
              ref="voucher"
            />

            <!-- 常见发票 -->
            <!-- <strong style="font-size: 15px; font-weight: 600;">常见发票</strong>
                        <el-button type="primary" style="margin: 16px 16px;" @click="$refs.invoice.click()"
                            :loading="flag.uploadNormalInvoice" icon="el-icon-upload2">批量上传</el-button>
                        <span>注明：最多一次可上传10张</span>
                        <div class="uploadImgWrap">
                            <div class="item" v-for="(item, index) in Normals" :key="index">
                                <i class="delete el-icon-delete" @click="handleInvoiceDelete(index)"></i>
                                <el-image class="img" :src="item.picUrl" :preview-src-list="[item.picUrl]">
                                    <div slot="error" class="image-slot"
                                        style="display: flex; justify-content: center; margin-top: 30px;">
                                        <i class="el-icon-picture-outline" style="font-size: 50px;"></i>
                                    </div>
                                </el-image>
                                <el-tooltip :content="item.picUrl">
                                    <p @click="downloadVoucher(item.picUrl)"
                                        style="width: 180px;white-space: nowrap;overflow: hidden; text-overflow: ellipsis;color:blue">
                                        {{
                                        item.picUrl
                                        }}</p>
                                </el-tooltip>
                            </div>
                        </div>
                        <el-pagination background @current-change="handleCurrentChange1"
                            :current-page.sync="pagination1.page" :page-size="pagination1.pagesize"
                            layout="total, prev, pager, next, jumper" :total="pagination1.total">
                        </el-pagination> -->

            <hr style="margin-top: 16px" />

            <!-- 特殊发票 -->
            <span style="font-size: 15px; font-weight: 600">上传发票</span>
            <el-button
              type="primary"
              style="margin: 16px 16px"
              @click="$refs.specialInvoice.click()"
              :loading="flag.uploadSpecialInvoice"
              icon="el-icon-upload2"
              >批量上传</el-button
            >
            <span>注明：最多一次可上传10张</span>
            <el-button
              type="primary"
              style="margin: 16px 16px"
              @click="NoSpecialInvoiceListonClick"
              :loading="flag.uploadSpecialInvoice"
              >上传无图发票</el-button
            >
            <!-- 表格 -->
            <vxe-table
              border
              resizable
              show-overflow
              :data="Specials"
              :edit-config="{ trigger: 'click', mode: 'cell' }"
              class="special-table"
              key="specialInvoice"
              v-if="flag.activeTab == '1'"
            >
              <vxe-table-column
                align="center"
                field="index"
                title="序号"
                width="14%"
              ></vxe-table-column>
              <vxe-table-column
                align="center"
                field="picUrl"
                title="发票图片"
                width="14%"
              >
                <template #default="{ row }">
                  <el-image
                    style="width: 40px; height: 40px; margin-top: 5px"
                    :src="row.picUrl"
                    :preview-src-list="[row.picUrl]"
                    v-if="row.picUrl"
                  ></el-image>
                  <span style="color: red" v-else>已由系统回填信息</span>
                </template>
              </vxe-table-column>
              <vxe-table-column
                align="center"
                field="invoiceCode"
                title="发票代码（必填）"
                width="14%"
                :edit-render="{ name: 'input', autoselect: true }"
              ></vxe-table-column>
              <vxe-table-column
                align="center"
                field="invoiceNum"
                title="发票号码（必填）"
                width="14%"
                :edit-render="{ name: 'input', autoselect: true }"
              ></vxe-table-column>
              <vxe-table-column
                align="center"
                field="CreateTime"
                title="开票日期（必填）"
                width="14%"
                :edit-render="{ autoselect: true }"
              >
                <template #edit="{ row }">
                  <el-input
                    v-model="row.CreateTime"
                    type="date"
                    value-format="yyyy-MM-dd"
                  />
                </template>
              </vxe-table-column>
              <vxe-table-column align="center" field="IsOcr" title="是否识别" width="14%">
                <template #default="{ row }">
                  <el-tag :type="row.IsOcr ? 'success' : 'danger'">{{
                    row.IsOcr ? "是" : "否"
                  }}</el-tag>
                </template>
              </vxe-table-column>
              <!-- <vxe-table-column align="center" field="money" title="开具金额（必填）" width="150"
                                :edit-render="{ name: 'input', autoselect: true }"></vxe-table-column>
                            <vxe-table-column align="center" field="total" title="价税合计" width="150"
                                :edit-render="{ name: 'input', autoselect: true }"></vxe-table-column>
                            <vxe-table-column align="center" field="verifyCode" title="校验码" width="150"
                                :edit-render="{ name: 'input', autoselect: true }"></vxe-table-column> -->
              <vxe-table-column align="center" title="操作" width="15%">
                <template #default="{ row }">
                  <el-button type="danger" @click="deleteSpecialInvoiceDelete(row.index)"
                    >删除
                  </el-button>
                </template>
              </vxe-table-column>
            </vxe-table>
            <!-- 分页 -->
            <el-pagination
              background
              @current-change="handleCurrentChange2"
              style="margin-top: 8px"
              :current-page.sync="pagination2.page"
              :page-size="pagination2.pagesize"
              layout="total, prev, pager, next, jumper"
              :total="pagination2.total"
            >
            </el-pagination>
          </div>
        </el-tab-pane>

        <el-tab-pane label="清单" name="2">
          <div style="height: 60vh; overflow-y: scroll">
            <!-- 提示 -->
            <el-alert style="margin-bottom: 8px" type="warning" :closable="false">
              <span slot="title">
                <span>温馨提示：上传与发票相关的清单、票据等。</span><br />
              </span>
            </el-alert>
            <el-button
              type="primary"
              :loading="flag.uploadingInvoice"
              @click="$refs.voucher.click()"
              icon="el-icon-upload2"
              >上传清单</el-button
            >

            <!-- 凭证列表 -->
            <div class="voucherWrap">
              <div class="item" v-for="(item, index) in Listings" :key="index">
                <i
                  class="delete el-icon-delete"
                  @click="handleVoucherDelete(pagination3.page, index)"
                ></i>
                <el-image
                  style="width: 200px; height: 100px; margin-bottom: 8px"
                  :src="item.picUrl"
                  :preview-src-list="[item.picUrl]"
                >
                  <div slot="error" class="image-slot">
                    <img
                      style="width: 200px; height: 100px"
                      @click="downloadVoucher(item.picUrl)"
                      :src="ossHost + 'fileDefaultBg.png'"
                      alt=""
                    />
                  </div>
                </el-image>
                <p @click="downloadVoucher(item.picUrl)">{{ item.name }}</p>
              </div>
            </div>
            <!-- 分页 -->
            <el-pagination
              background
              @current-change="handleCurrentChange3"
              style="margin-top: 8px"
              :current-page.sync="pagination3.page"
              :page-size="pagination3.pagesize"
              layout="total, prev, pager, next, jumper"
              :total="pagination3.total"
            >
            </el-pagination>
          </div>
        </el-tab-pane>
      </el-tabs>
      <!-- 按钮 -->
      <div style="text-align: center; margin-top: 8px">
        <!-- <div v-if="flag.activeTab == 0" style="display: inline-block;margin-right: 10px;">
                  <el-button type="primary" size="mini" :loading="flag.uploadingInvoice" @click="batchInvoiceAction(3)"
                    :disabled="!chosedElectronInvoice.length">全部下载</el-button>
                  <el-button type="primary" size="mini" :loading="flag.uploadingInvoice" @click="batchInvoiceAction(2)"
                    :disabled="!chosedElectronInvoice.length">全部冲红</el-button>
                  <el-button type="primary" size="mini" :loading="flag.uploadingInvoice" @click="batchInvoiceAction(1)"
                    :disabled="!chosedElectronInvoice.length">全部重开</el-button>
                </div> -->
        <el-button
          type="primary"
          size="mini"
          :loading="flag.uploadingInvoice"
          @click="handleCloseUploadInvoiceDialog"
          >确认</el-button
        >
      </div>
    </el-dialog>
    <!-- 发票包详情 -->
    <el-dialog
      width="1200px"
      title="查看详情"
      :visible.sync="flag.invoiceDetailDialog"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <el-table
        :data="detailData"
        :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
        v-loading="loading"
        key="detailData"
      >
        <el-table-column
          type="index"
          label="序号"
          width="50"
          align="center"
          fixed="left"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="BBID"
          label="运单号"
          width="200"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="ApplicantName"
          label="货主单位"
          width="180"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="InvoiceTypeName"
          label="发票类型"
          width="180"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="BusinessTypeName"
          label="业务类型"
          width="180"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="ScenarioTypeName"
          label="场景类型"
          width="180"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="SuplierRate"
          label="费率"
          width="150"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{ scope.row.SuplierRateStr }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="Shipper"
          label="托运人"
          show-overflow-tooltip
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="ShipownerName"
          label="承运人"
          show-overflow-tooltip
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="ShipownerIDCard"
          label="承运人身份证号"
          show-overflow-tooltip
          align="center"
          width="180"
        >
        </el-table-column>
        <el-table-column
          prop="ShipownerPhone"
          label="承运人手机号"
          show-overflow-tooltip
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="PayeeName"
          label="代收人"
          show-overflow-tooltip
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="PayeeIDCard"
          label="代收人身份证号"
          show-overflow-tooltip
          align="center"
          width="180"
        >
        </el-table-column>
        <el-table-column
          prop="PayeePhone"
          label="代收人手机号"
          show-overflow-tooltip
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="ShipName"
          label="船舶名称"
          show-overflow-tooltip
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="GoodsName"
          label="货物名称"
          show-overflow-tooltip
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="GoodsUnitName"
          label="货物单位"
          show-overflow-tooltip
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="OriginAddress"
          label="起始地"
          show-overflow-tooltip
          align="center"
          width="200"
        >
        </el-table-column>
        <el-table-column
          prop="ArriveAddress"
          label="到达地"
          show-overflow-tooltip
          align="center"
          width="200"
        >
        </el-table-column>
        <el-table-column
          prop="OriginTime"
          label="起始时间"
          show-overflow-tooltip
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="ArriveTime"
          label="到达时间"
          show-overflow-tooltip
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="Counts"
          label="货物数量"
          show-overflow-tooltip
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="Price"
          label="运输单价"
          show-overflow-tooltip
          align="center"
          width="150"
        >
          <template slot-scope="scope">
            <span style="color: #ff8b17">{{ scope.row.Price | formatMoney }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="CarriageFee"
          label="运费"
          show-overflow-tooltip
          align="center"
          width="150"
        >
          <template slot-scope="scope">
            /
            <!-- <span style="color:#FF8B17" v-if="taskTypeID.includes('DK')">{{ scope.row.CarriageFee |
                            formatMoney }}</span>
                        <span style="color:#FF8B17" v-else>/</span> -->
          </template>
        </el-table-column>
        <el-table-column
          prop="SupplierProfit"
          label="服务费/税费"
          show-overflow-tooltip
          align="center"
          width="150"
          v-if="!taskTypeID.includes('DK')"
        >
          <template slot-scope="scope">
            <span style="color: #ff8b17">{{
              scope.row.SupplierProfit | formatMoney
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="TotalAmount"
          label="运单总金额"
          show-overflow-tooltip
          align="center"
          width="150"
          v-if="!taskTypeID.includes('DK')"
        >
          <template slot-scope="scope">
            <span style="color: #ff8b17">{{ scope.row.TotalAmount | formatMoney }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="TrustFee"
          label="委托代开运费"
          show-overflow-tooltip
          align="center"
          width="150"
          v-if="taskTypeID.includes('DK')"
        >
          <template slot-scope="scope">
            <span style="color: #ff8b17">/</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="SupplierProfit"
          label="委托代开服务费/税费"
          show-overflow-tooltip
          align="center"
          width="150"
          v-if="taskTypeID.includes('DK')"
        >
          <template slot-scope="scope">
            <span style="color: #ff8b17">{{
              scope.row.SupplierProfit | formatMoney
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="TotalAmount"
          label="委托代开运单总金额"
          show-overflow-tooltip
          align="center"
          width="150"
          v-if="taskTypeID.includes('DK')"
        >
          <template slot-scope="scope">
            <span style="color: #ff8b17">{{ scope.row.TotalAmount | formatMoney }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="ThirdBillID"
          label="客户单号"
          show-overflow-tooltip
          align="center"
          width="150"
        >
        </el-table-column>
      </el-table>
      <div style="margin: 10px 0 0 0">
        <!-- 分页 -->
        <el-pagination
          class="pagination"
          background
          @current-change="handleCurrentDetailChange"
          :current-page.sync="paginationDetail.page"
          :page-size="paginationDetail.pagesize"
          layout="total, prev, pager, next, jumper"
          :total="paginationDetail.total"
        >
        </el-pagination>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  GetInvoicePackages,
  StagingCombined,
  CancelCombined,
  GetUploadInvoiceList,
  DiscernInvoice,
  UploadInvoice,
  SendInvoiceToOwner,
  GetPackagesBBSDetail,
  CompleteInvoice,
} from "@/api/purchaseManage/markOut";
import { upLoadImgByUrl } from "@/api/common/common";
import { _isNumberOrLetter, _integer, _polishing, _isAllNumber } from "@/utils/verify";
import basicMixin from "@/mixins/basic";
export default {
  mixins: [basicMixin],
  data() {
    return {
      bsid: "", // 运单编号
      searchForm: {
        type: 0, // 发票状态
      },
      flag: {
        printDataStoreIng: false, // 暂存按钮loading
        recombineLoading: false, // 重新组合按钮
        uploadInvoiceDialog: false, // 上传发票弹窗
        activeTab: "1", // 上传发票tab
        uploadingInvoice: false, //上传发票中
        uploadNormalInvoice: false, //上传正常发票中
        uploadSpecialInvoice: false, //上传特殊发票中
        uploadDialogLoading: false, // 打开上传发票弹窗loading
        isFinishInvoice: false, // 是否开票完成
        invoiceDetailDialog: false, // 查看详情弹窗
      },
      loading: false,
      invoicePackagesList: [],
      pagination: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      detailData: [],
      paginationDetail: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      pagination1: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      pagination2: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      pagination3: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      //发票状态
      invoiceStatusList: [
        {
          Name: "全部",
          Code: 0,
        },
        {
          Name: "已开票",
          Code: 1,
        },
        {
          Name: "未开票",
          Code: 2,
        },
      ],
      currentItem: {
        Normals: [],
        Specials: [],
        Listings: [],
      },
      currentBPID: "",
      taskTypeID: "",
    };
  },
  methods: {
    NoSpecialInvoiceListonClick() {
      this.currentItem.Specials.push({
        index: this.currentItem.Specials.length + 1,
        picUrl: "",
        invoiceNum: null,
        invoiceCode: null, //发票代码
        money: null, //开具金额
        total: null, //价税合计
        verifyCode: null, //校验码
        CreateTime: null,
        IsOcr: false, // 是否Ocr识别
      });
      this.pagination2.total = this.pagination2.total + 1;
    },
    // 改变发票状态查询
    changeType() {
      this.pagination.page = 1;
      this.getInvoicePackages();
    },
    // 获取列表
    getInvoicePackages() {
      this.loading = true;
      GetInvoicePackages({
        bsid: this.bsid,
        applicantUserID: this.$parent.settlementInfo.ApplicantUserID,
      })
        .then((res) => {
          this.invoicePackagesList = res.data || [];
          this.pagination.total = this.invoicePackagesList.length;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.pagination.page = e;
    },
    // 返回
    goBack() {
      if (this.$route.query.activeCmpt) {
        this.$router.push(
          `/commission/index/purchaseInvoicing?activeCmpt=${this.$route.query.activeCmpt}`
        );
      } else {
        this.$router.push(`/commission/index/purchaseInvoicing`);
      }
    },
    // 保存进度
    stagingCombined() {
      const loading = this.$loading({
        lock: true,
        text: "暂存中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.flag.printDataStoreIng = true;
      StagingCombined({
        bsid: this.bsid,
      })
        .then((res) => {
          this.$message.success("暂存成功");
        })
        .finally(() => {
          this.flag.printDataStoreIng = false;
          loading.close();
        });
    },
    // 重新组合
    recombine() {
      this.flag.recombineLoading = true;
      CancelCombined({
        bsid: this.bsid,
      })
        .then((res) => {
          this.$emit("changeStatus", 10);
        })
        .finally(() => {
          this.flag.recombineLoading = false;
        });
    },
    //显示上传弹窗
    uploadInvoice(item) {
      this.flag.uploadDialogLoading = true;
      this.currentBPID = item.BPID;
      this.currentItem.Specials = [];
      GetUploadInvoiceList({
        bpid: item.BPID,
      })
        .then((res) => {
          // this.currentItem.Normals = res.data.Normals.map(it => {
          //     return {
          //         index: res.data.Normals.length + 1,
          //         picUrl: it.InvoiceImgUrl,
          //         invoiceNum: it.InvoiceNumber,
          //         invoiceContent: it.InvoiceContent,
          //         money: it.InvoiceAmount, //开具金额
          //         total: it.InvoiceTotalAmount, //价税合计
          //         verifyCode: it.CheckCode, //校验码
          //         invoiceCode: it.InvoiceCode,
          //         CreateTime: it.CreateTime.replace(/年/g, '-').replace(/月/g, '-').replace(/日/g, '')
          //     }
          // })
          res.data.Normals.map((it, idx) => {
            this.currentItem.Specials.push({
              index: this.currentItem.Specials.length + 1,
              picUrl: it.InvoiceImgUrl,
              invoiceNum: it.InvoiceNumber,
              money: it.InvoiceAmount, //开具金额
              total: it.InvoiceTotalAmount, //价税合计
              verifyCode: it.CheckCode, //校验码
              invoiceCode: it.InvoiceCode,
              CreateTime: it.CreateTime.replace(/年/g, "-")
                .replace(/月/g, "-")
                .replace(/日/g, ""),
              IsOcr: it.IsOcr,
            });
          });
          res.data.Specials.map((it, idx) => {
            this.currentItem.Specials.push({
              index: this.currentItem.Specials.length + 1,
              picUrl: it.InvoiceImgUrl,
              invoiceNum: it.InvoiceNumber,
              money: it.InvoiceAmount, //开具金额
              total: it.InvoiceTotalAmount, //价税合计
              verifyCode: it.CheckCode, //校验码
              invoiceCode: it.InvoiceCode,
              CreateTime: it.CreateTime.replace(/年/g, "-")
                .replace(/月/g, "-")
                .replace(/日/g, ""),
              IsOcr: it.IsOcr,
            });
          });
          this.currentItem.Listings = res.data.Listings.map((it) => {
            return {
              index: res.data.Listings.length + 1,
              picUrl: it,
              name: it,
            };
          });
          this.pagination1.total = this.currentItem.Normals.length;
          this.pagination2.total = this.currentItem.Specials.length;
          this.pagination3.total = this.currentItem.Listings.length;
          this.flag.uploadInvoiceDialog = true;
        })
        .finally(() => {
          this.flag.uploadDialogLoading = false;
        });
    },
    //分页回调
    handleCurrentChange1(e) {
      this.pagination1.page = e;
    },
    //分页回调
    handleCurrentChange2(e) {
      this.pagination2.page = e;
    },
    //分页回调
    handleCurrentChange3(e) {
      this.pagination3.page = e;
    },
    //关闭上传弹窗
    handleCloseUploadInvoiceDialog() {
      let flag = false; //是否还有必填项每天
      this.currentItem.Specials.forEach((item) => {
        if (!item.invoiceCode || !item.invoiceNum || !item.CreateTime) {
          flag = true;
        }
      });
      if (flag) {
        this.$message.warning("发票代码、发票号码、开票日期都为必填项");
      } else {
        try {
          this.currentItem.Specials.forEach((item) => {
            this.currentItem.Specials.forEach((el) => {
              if (
                item.invoiceCode === el.invoiceCode &&
                item.invoiceNum === el.invoiceNum &&
                item.index != el.index
              ) {
                this.$message.error("发票中有重复发票");
                throw new Error();
              }
            });
            if (!_isNumberOrLetter(item.invoiceCode)) {
              this.$message.error(`发票-序号${item.index}的发票代码只能为数字和字母`);
              throw new Error();
            }
            if (item.invoiceCode.length > 32) {
              this.$message.error(`发票-序号${item.index}的发票代码需小于32个字符`);
              throw new Error();
            }
            if (!_isNumberOrLetter(item.invoiceNum)) {
              this.$message.error(`发票-序号${item.index}的发票号码只能为数字和字母`);
              throw new Error();
            }
            if (item.invoiceNum.length > 32) {
              this.$message.error(`发票-序号${item.index}的发票号码需小于32个字符`);
              throw new Error();
            }
            // if (!_integer(item.money)) {
            //     this.$message.error(`特殊发票-序号${item.index}的开具金额只能为数字，且仅保留两位小数`)
            //     throw new Error()
            // }
            // if (Number(item.money) > 10000000) {
            //     this.$message.error(`特殊发票-序号${item.index}的开具金额不能大于一千万`)
            //     throw new Error()
            // }
            // if (item.total) {
            //     if (!_integer(item.total)) {
            //         this.$message.error(`特殊发票-序号${item.index}的价税合计只能为数字，且仅保留两位小数`)
            //         throw new Error()
            //     }
            //     if (Number(item.total) > 20000000) {
            //         this.$message.error(`特殊发票-序号${item.index}的价税合计不能大于二千万`)
            //         throw new Error()
            //     }
            // }
            // if (item.verifyCode) {
            //     if (!_isAllNumber(item.verifyCode)) {
            //         this.$message.error(`特殊发票-序号${item.index}的校验码只能为纯数字`)
            //         throw new Error()
            //     }
            // }
          });
          //处理金额为后面有两位小数点的格式
          // this.currentItem.Specials.forEach(item => {
          //     item.money = item.money ? _polishing(item.money) : null
          //     item.total = item.total ? _polishing(item.total) : null
          // })
          this.confirmUploadInvoice();
        } catch (error) {
          console.log(error);
        }
      }
    },
    // 确认上传发票
    confirmUploadInvoice() {
      let normals = [];
      // this.currentItem.Normals.map(it => {
      //     normals.push({
      //         invoiceNumber: it.invoiceNum,
      //         invoiceImgUrl: it.picUrl,
      //         createTime: it.CreateTime,
      //         invoiceCode: it.invoiceCode,
      //         invoiceContent: it.invoiceContent,
      //         invoiceAmount: it.money,
      //         invoiceTotalAmount: it.total,
      //         checkCode: it.verifyCode,
      //         fileName: ""
      //     })
      // })
      let specials = [];
      this.currentItem.Specials.map((it) => {
        if (it.IsOcr) {
          normals.push({
            invoiceNumber: it.invoiceNum,
            invoiceImgUrl: it.picUrl,
            createTime: it.CreateTime,
            invoiceCode: it.invoiceCode,
            invoiceContent: "",
            invoiceAmount: it.money,
            invoiceTotalAmount: it.total,
            checkCode: it.verifyCode,
            fileName: "",
            IsOcr: it.IsOcr,
          });
        } else {
          specials.push({
            invoiceNumber: it.invoiceNum,
            invoiceImgUrl: it.picUrl,
            createTime: it.CreateTime,
            invoiceCode: it.invoiceCode,
            invoiceContent: "",
            invoiceAmount: it.money,
            invoiceTotalAmount: it.total,
            checkCode: it.verifyCode,
            fileName: "",
            IsOcr: it.IsOcr,
          });
        }
      });
      UploadInvoice({
        bpid: this.currentBPID,
        normals,
        specials,
        listings: this.currentItem.Listings.map((it) => {
          return it.picUrl;
        }),
      }).then((res) => {
        this.changeType();
        this.flag.uploadInvoiceDialog = false;
      });
    },
    //上传正常发票
    handleInvoiceUpload(e) {
      let fileList = e.srcElement.files || e.target.files;
      if (fileList.length > 10) {
        this.$message.error("单次上传不可超过十张发票");
        this.$refs.invoice.value = null;
        return;
      }
      this.flag.uploadingInvoice = true;
      this.flag.uploadNormalInvoice = true;
      let count = 0; //已经上传完毕了多少条
      let fileListLength = fileList.length;
      for (let i = 0; i < fileListLength; i++) {
        let formData = new FormData();
        formData.append("file", fileList[i]);
        DiscernInvoice(formData)
          .then((res) => {
            if (!res.data.InvoiceCode || !res.data.InvoiceNum) {
              this.$message.warning(
                "该发票识别不出发票号码等信息，请上传到特殊发票中再手动输入信息"
              );
              return;
            }
            let hasSame = false;
            this.currentItem.Normals.forEach((el) => {
              if (
                el.invoiceNum === res.data.InvoiceNum &&
                el.invoiceCode === res.data.InvoiceCode
              ) {
                hasSame = true;
              }
            });
            //如果有相同的
            if (hasSame) {
              this.$message.warning("请勿上传重复的发票");
            } else {
              //没有相同的
              //处理实例存储的发票号
              //处理dialog组件的发票号
              this.currentItem.Normals.push({
                index: this.currentItem.Normals.length + 1,
                picUrl: res.data.InvoiceImgUrl,
                invoiceNum: res.data.InvoiceNum,
                invoiceContent: res.data.InvcieContent,
                money: res.data.AmountIssued, //开具金额
                total: res.data.TaxIncreaseAlloy, //价税合计
                verifyCode: res.data.CheckCode, //校验码
                invoiceCode: res.data.InvoiceCode,
                CreateTime: res.data.InvoiceTime.replace(/年/g, "-")
                  .replace(/月/g, "-")
                  .replace(/日/g, ""),
              });

              this.pagination1.total = this.pagination1.total + 1;
            }
          })
          .finally(() => {
            this.$refs.invoice.value = null;
            count = count + 1;
            if (count === fileListLength) {
              this.flag.uploadingInvoice = false;
              this.flag.uploadNormalInvoice = false;
            }
          });
      }
    },
    //删除正常发票
    handleInvoiceDelete(index) {
      this.$confirm("确认删除该发票吗", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.pagination1.total = this.pagination1.total - 1;
          this.currentItem.Normals.splice(index, 1);
        })
        .catch();
    },
    //上传清单
    handleVoucherUpload(e) {
      let fileSrc = e.srcElement.files[0];
      let type = fileSrc.name.split(".");
      this.flag.uploadingInvoice = true;
      var reader = new FileReader();
      reader.readAsDataURL(fileSrc);
      reader.onload = () => {
        upLoadImgByUrl({
          FileBase64: reader.result,
          FileType: "Evidence",
          FileSuffix: "." + type[type.length - 1],
        })
          .then((res) => {
            this.currentItem.Listings.push({
              index: this.currentItem.Listings.length + 1,
              picUrl: res.data,
              name: res.data,
            });
            this.pagination3.total = this.pagination3.total + 1;
          })
          .finally(() => {
            this.$refs.voucher.value = null;
            this.flag.uploadingInvoice = false;
          });
      };
    },
    //删除一个凭证
    handleVoucherDelete(page, index) {
      this.$confirm("确认删除该凭证吗", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.pagination3.total = this.pagination3.total - 1;
          this.currentItem.Listings.splice(
            (page - 1) * this.pagination3.pagesize + index,
            1
          );
        })
        .catch();
    },
    //下载凭证
    downloadVoucher(url) {
      window.open(url);
    },
    //上传特殊发票信息
    handleSpecialInvoiceUpload(e) {
      let fileList = e.srcElement.files || e.target.files;
      if (fileList.length > 10) {
        this.$message.error("单次上传不可超过十张发票");
        this.$refs.specialInvoice.value = null;
        return;
      }
      this.flag.uploadingInvoice = true;
      this.flag.uploadSpecialInvoice = true;
      let count = 0; //已经上传完毕了多少条
      let fileListLength = fileList.length;
      for (let i = 0; i < fileListLength; i++) {
        let formData = new FormData();
        formData.append("file", fileList[i]);
        DiscernInvoice(formData)
          .then((res) => {
            this.currentItem.Specials.push({
              index: this.currentItem.Specials.length + 1,
              picUrl: res.data.InvoiceImgUrl,
              invoiceNum: res.data.InvoiceNum,
              invoiceCode: res.data.InvoiceCode, //发票代码
              money: res.data.AmountIssued, //开具金额
              total: res.data.TaxIncreaseAlloy, //价税合计
              verifyCode: res.data.CheckCode, //校验码
              CreateTime: res.data.InvoiceTime,
              IsOcr: res.data.IsOcr, // 是否Ocr识别
            });
            this.pagination2.total = this.pagination2.total + 1;
          })
          .finally(() => {
            this.$refs.specialInvoice.value = null;
            count = count + 1;
            if (count == fileListLength) {
              this.flag.uploadingInvoice = false;
              this.flag.uploadSpecialInvoice = false;
            }
          });
      }
    },
    //删除特殊发票
    deleteSpecialInvoiceDelete(index) {
      this.$confirm("确认删除该发票吗", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.currentItem.Specials.forEach((item, inx) => {
            if (item.index == index) {
              this.pagination2.total = this.pagination2.total - 1;
              this.currentItem.Specials.splice(inx, 1);
            }
          });
          this.currentItem.Specials.forEach((item, index) => {
            item.index = index + 1;
          });
        })
        .catch();
    },
    // 完成开票
    finishInvoice() {
      if (
        this.invoicePackagesList.filter(
          (it) => (it.Status == 0 || it.Status == 2) && it.FeeType != 1
        ).length
      ) {
        this.$message.warning("还有未上传发票的数据！");
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: "完成开票中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      CompleteInvoice({
        bsid: this.bsid,
        applicantUserID: this.$parent.settlementInfo.ApplicantUserID,
      })
        .then((res) => {
          this.$message.success("开票完成");
          this.flag.isFinishInvoice = true;
        })
        .finally(() => {
          loading.close();
        });
    },
    // 发送至货主
    sendBoss() {
      this.$confirm("确定发送给货主吗？", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          SendInvoiceToOwner({
            bsid: this.bsid,
          }).then((res) => {
            this.$router.push(
              "/commission/index/purchaseInvoicing?activeCmpt=SellersAlready"
            );
            this.$message.success("发送成功");
          });
        })
        .catch();
    },
    // 查看详情
    invoiceDetail(row) {
      this.currentBPID = row.BPID;
      this.flag.invoiceDetailDialog = true;
      this.detailData = [];
      this.paginationDetail.page = 1;
      this.getPackagesBBSDetail(row.BPID);
    },
    // 获取详情列表
    getPackagesBBSDetail(bpid) {
      this.loading = true;
      GetPackagesBBSDetail({
        bpid,
        pageSize: this.paginationDetail.pagesize,
        pageIndex: this.paginationDetail.page,
        applicantUserID: this.$parent.settlementInfo.ApplicantUserID,
      })
        .then((res) => {
          this.detailData = res.data.DataList;
          this.paginationDetail.total = Number(res.data.TotalCount);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleCurrentDetailChange(e) {
      this.paginationDetail.page = e;
      this.getPackagesBBSDetail(this.currentBPID);
    },
  },
  created() {
    this.bsid = this.$route.query.bsid ? this.$route.query.bsid : "";
    this.taskTypeID = this.$route.query.taskTypeID ? this.$route.query.taskTypeID : "";
    // status为20，表示已完成开票还未发送至货主
    if (this.$parent.status == 20) {
      this.flag.isFinishInvoice = true;
    }
    this.getInvoicePackages();
  },
  computed: {
    // 列表分页
    currentInvoicePackagesList() {
      try {
        return this.invoicePackagesList.slice(
          this.pagination.pagesize * (this.pagination.page - 1),
          this.pagination.pagesize * this.pagination.page
        );
      } catch (error) {}
    },
    //正常发票分页
    Normals() {
      try {
        return this.currentItem.Normals.slice(
          this.pagination1.pagesize * (this.pagination1.page - 1),
          this.pagination1.pagesize * this.pagination1.page
        );
      } catch (error) {}
    },
    //特殊发票分页
    Specials() {
      try {
        return this.currentItem.Specials.slice(
          this.pagination2.pagesize * (this.pagination2.page - 1),
          this.pagination2.pagesize * this.pagination2.page
        );
      } catch (error) {}
    },
    //发票凭证分页
    Listings() {
      try {
        return this.currentItem.Listings.slice(
          this.pagination3.pagesize * (this.pagination3.page - 1),
          this.pagination3.pagesize * this.pagination3.page
        );
      } catch (error) {}
    },
  },
};
</script>
<style lang="scss">
.invoicing-process {
  .voucherWrap {
    display: flex;
    flex-wrap: wrap;
    width: 1100px;

    .item {
      width: 18%;
      margin: 16px 10px;
      position: relative;

      .delete {
        cursor: pointer;
        position: absolute;
        font-size: 30px;
        position: absolute;
        bottom: 30px;
        right: 30px;
        z-index: 9999;
      }

      p {
        width: 200px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        color: blue;
        cursor: pointer;
      }
    }
  }

  .uploadImgWrap {
    display: flex;
    flex-wrap: wrap;
    width: 1100px;
    min-height: 50px;

    .item {
      width: 18%;
      margin: 16px 10px;
      position: relative;
      display: flex;
      flex-direction: column;

      .delete {
        cursor: pointer;
        position: absolute;
        font-size: 30px;
        position: absolute;
        bottom: 30px;
        right: 30px;
        z-index: 9999;
      }

      span {
        width: 200px;
        text-align: center;
      }
    }
  }
}

.special-table {
  .vxe-table--empty-placeholder {
    top: 50px !important;
  }

  table {
    width: 100% !important;
  }

  th,
  td {
    width: auto !important;
  }
}
</style>
